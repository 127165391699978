<mat-list>
  <!-- <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="active">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item> -->
  <mat-list-item routerLink="{{routes.EXPORTS}}" routerLinkActive="active">
    <mat-icon>get_app</mat-icon> Exports
  </mat-list-item>
  <mat-list-item routerLink="{{routes.STORES}}" routerLinkActive="active">
    <mat-icon>store</mat-icon> Stores
  </mat-list-item>
  <mat-list-item routerLink="{{routes.PRODUCTS}}" routerLinkActive="active">
    <mat-icon>menu_book</mat-icon> Products
  </mat-list-item>
</mat-list>

