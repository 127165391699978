// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBHwUzRQEAiqwf0Yv8or_pcpjdwa-BHDrE",
    authDomain: "stock-check-48f78.firebaseapp.com",
    databaseURL: "https://stock-check-48f78.firebaseio.com",
    projectId: "stock-check-48f78",
    storageBucket: "stock-check-48f78.appspot.com",
    messagingSenderId: "1096398933026",
    appId: "1:1096398933026:web:47fef88382b494356ece7b",
    measurementId: "G-YJLZD3CDC4"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
