import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services';
import { routes } from '../../../../consts';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  public todayDate: Date = new Date();
  public routers: typeof routes = routes;

  constructor(
    private service: AuthService,
    private router: Router
  ) {
    if (this.service.isLoggedIn()){
      this.router.navigate([this.routers.STORES]).then();
    }
  }

  public async sendLoginForm(e) {
    await this.service.login(e.email, e.password);
    console.log("4 ran");
    await this.router.navigate([this.routers.STORES]);
    //this.service.reload();
    console.log("nav ran");
  }

  // public sendSignForm(): void {
  //   this.service.sign();

  //   this.router.navigate([this.routers.DASHBOARD]).then();
  // }
}
