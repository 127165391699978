import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderModule } from './header/header.module';
import { MaterialModule } from './material/material.module';
import { ExcelService, SharedService } from './services';

import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsMenuComponent } from './ui-elements/settings-menu/settings-menu.component';
import { DateMenuComponent } from './ui-elements/date-menu/date-menu.component';
import { LayoutComponent } from './layout/layout.component';

import { ImportDialogComponent } from './dialogs/import-dialog/import-dialog.component';
import { LoadingDialogComponent } from './dialogs/loading-dialog/loading-dialog.component';

@NgModule({
  declarations: [
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    ImportDialogComponent,
    LoadingDialogComponent,
  ],
  imports: [
    HeaderModule,
    MaterialModule,
    RouterModule,
    CommonModule,
  ],
  exports: [
    MaterialModule,
    HeaderModule,
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent
  ],
  providers: [ExcelService, SharedService],
  entryComponents: [
    ImportDialogComponent,
    LoadingDialogComponent,
  ]
})
export class SharedModule { }
