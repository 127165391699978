import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../models';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private route: ActivatedRoute,  
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) { }

  // public login(email, password): Promise<any> {
  //   this.afAuth.signInWithEmailAndPassword(email, password)
  //   .then((result) => {
  //     //this.SetUserData(result.user);
  //     const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
  //     let user = userRef.valueChanges();
  //     user.subscribe(data => localStorage.setItem('token', JSON.stringify(data)) )
  //     console.log("2 ran");
  //     return ;
  //   }).catch((error) => {
  //     window.alert(error.message)
  //   })
  //   return Promise.resolve()
  // }

  public async login(email, password): Promise<any> {
    let result =  await this.afAuth.signInWithEmailAndPassword(email, password);
    console.log("2 ran");
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
    const user = userRef.valueChanges();
    user.subscribe(data => localStorage.setItem('token', JSON.stringify(data)) )
    console.log("3 ran");
    return Promise.resolve()
  }

  public isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('token'));
    if(user){
      return true;
    }
    return false;
  }

  public signOut(): void {
    localStorage.removeItem('token');
  }

  public getUser(): Observable<User> {
    const user = JSON.parse(localStorage.getItem('token'));
    if(user){
      return of(user);
    }
  }
  private SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

   // Send email verfificaiton when new user sign up
   SendVerificationMail() {
    return this.afAuth.currentUser.then((user) => {
      user.sendEmailVerification();
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  reload(): void  {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], { relativeTo: this.route });
  }


}
