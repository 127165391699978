import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public sacoda: string = 'https://sacodaserv.com';
  public sacodaAbout: string = 'https://sacodaserv.com/?page_id=26';
}
