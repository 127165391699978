export class Store {
  id: string;
  count: number;
  name: string;
  address: string;
  code: number;
  updated_product_count: number;
  completed: boolean;
  last_completed: string;
  last_completed_by: string;
  }