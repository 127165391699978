import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { DashboardPageComponent } from './pages/dashboard/containers';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './pages/auth/guards';

const routes: Routes = [
  { path: '', redirectTo: '/stores', pathMatch: 'full'},
  {
    path: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: DashboardPageComponent
  },
  {
    path: 'exports',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/exports/exports.module').then(m => m.ExportsModule)
  },
  {
    path: 'stores',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresModule)
  },
  {
    path: 'stores/:id',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'products',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
