<h5 mat-dialog-title>{{data.title}}</h5>
<div mat-dialog-content>
    <div class="file">
        <label class="file-label">
            <input type="file" (change)="onFileChange($event)" multiple="false"  accept={{data.fileTypes}}>
        </label>
    </div>
</div>
<mat-dialog-actions >
    <button [mat-dialog-close] class="mat-raised-button">{{data.denyButton}}</button>
    <span class="spacer"></span>
    <button class="mat-raised-button mat-primary"(click)="import()">{{data.confirmButton}}</button>
</mat-dialog-actions>