import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ExcelService } from '../../services/excel.service';
import { BareProduct } from '../../../pages/products/models';
import { BareStore } from '../../../pages/stores/models';

export interface DialogData {
  title: "";
  confirmButton: "";
  denyButton: "";
  fileTypes: "";
  dialogDataClass: string;
}


@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private excelSrv: ExcelService, private dialogRef: MatDialogRef<ImportDialogComponent>) { }

  importData = [];

  ngOnInit(): void {
  }

  onFileChange(evt: any) {
    console.log('ran')
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelSrv.importFromFile(bstr);
      let header: string[];
      if(this.data.dialogDataClass == "Product"){
        header = Object.getOwnPropertyNames(new BareProduct());
      }else{
        header = Object.getOwnPropertyNames(new BareStore());
      }
      const importedData = data.slice(1);

      this.importData = importedData.map(arr => {
        const obj = {};
        for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = arr[i];
        }
        if(this.data.dialogDataClass == "Product"){
          return <BareProduct>obj;
        }else{
          return <BareStore>obj;
        }
      })
    };
    reader.readAsBinaryString(target.files[0]);
  }

  import(){
    if(this.importData.length > 0){
      this.dialogRef.close(this.importData);
    }else{
      this.dialogRef.close(null);
      console.log("No data in file");
    } 
  }


}
