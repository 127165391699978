import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

    constructor(private _snackBar: MatSnackBar,public router: Router,private route: ActivatedRoute, ) { }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
          duration: 2500,
        });
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }
}