<button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
  <mat-icon class="user-button__icon">person</mat-icon>
</button>
<mat-menu #userMenu="matMenu" xPosition="before">
  <div class="user-menu-title">
    <p class="user-menu-title__name">{{user.displayName}}</p>
  </div>
  <!-- <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">person</mat-icon>Profile
  </button> -->
  <div class="sign-button-wrapper">
    <button class="sign-button" mat-flat-button (click)="signOutEmit()">Sign out</button>
  </div>
</mat-menu>
