<div class="auth-page">
  <div class="auth-page__content-block">
    <div class="auth-page__content-wrapper">
      <mat-tab-group class="auth-page__group">
        <mat-tab label="Login">
          <h4 class="auth-page__group-title">Welcome!</h4>
          <p class="auth-page__group-sub-title">Sign In</p>
          <app-login-form (sendLoginForm)="sendLoginForm($event)"></app-login-form>
        </mat-tab>

        <!-- <mat-tab label="New User">
          <h4 class="auth-page__group-title">Welcome!</h4>
          <p class="auth-page__group-sub-title">Request an account</p>
          <app-sign-form (sendSignForm)="sendSignForm()"></app-sign-form>
        </mat-tab> -->
      </mat-tab-group>

      <p class="auth-page__footer-title">© 2009-{{todayDate | year}} <a href="https://sacodaserv.com/">Sacoda Serv</a>, LTD. All rights reserved.</p>
    </div>
  </div>
  <div class="auth-page__logo">
    <div class="auth-page__logo-wrapper">
      <img class="auth-page__logo-img" src="./assets/auth/logo.png" style="width: 50%; height: 50%;"alt="logo">
      <h6 class="auth-page__logo-title">Stock Check</h6>
    </div>
  </div>
</div>
